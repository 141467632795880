import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IBCMCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import './employee.scss';
import './bcm-character-dynamic.scss';
import { SectionHeader } from '../modules/common/components/section-header';
import { Alert, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCube,
  faHandPointRight,
  faHistory,
  faRotate
} from '@fortawesome/free-solid-svg-icons';
import ScrollspyNav from 'react-scrollspy-nav';
import { BCMCharacter } from '../modules/bcm/common/components/bcm-character';
import { BCMRarity } from '../modules/bcm/common/components/bcm-rarity';
import { BCMSeasonal } from '../modules/bcm/common/components/bcm-seasonal';
import { BCMClass } from '../modules/bcm/common/components/bcm-class';
import { BCMType } from '../modules/bcm/common/components/bcm-type';
import { BCMStat } from '../modules/bcm/common/components/bcm-stat';
import { BCMStatus } from '../modules/bcm/common/components/bcm-status';
import { RatingBox } from '../modules/common/components/rating-box';
import { YoutubeEmbed } from '../modules/common/components/youtube-embed';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IBCMCharacterNodes {
  nodes: IBCMCharacter[];
}

interface IBCMCharacterEntry {
  currentUnit: IBCMCharacterNodes;
}

interface IProps {
  data: IBCMCharacterEntry;
}

const BCMCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [showEnhancedSkill, setShowEnhancedSkill] = useState(false);

  return (
    <DashboardLayout
      className={'generic-page character-page character-bcm'}
      game="bcm"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <div className="character-header ag">
        <BCMCharacter mode="icon" slug={character.slug} />
        <div className="character-details">
          <h1>
            {character.name}{' '}
            {character.isNew && <span className="tag new">NEW!</span>}{' '}
            {character.isUpdated && <span className="tag buffed">Updated</span>}{' '}
          </h1>
          <div className="character-roles-and-types">
            <BCMRarity rarity={character.rarity} />
            <BCMClass classChar={character.class} />
            <BCMType classChar={character.attribute} />
            {character.isCharacterSeasonal && (
              <BCMSeasonal season={character.season} />
            )}
          </div>
        </div>
        <div className="character-ratings">
          {!character.futureCharacter && character.ratings.kr_pvp != '1' ? (
            <>
              <RatingBox
                game="nikke"
                rating={character.ratings.kr_pve}
                ratingName="KR/JPN PVE"
              />
              <RatingBox
                game="nikke"
                rating={character.ratings.kr_pvp}
                ratingName="KR/JPN PVP"
              />
            </>
          ) : (
            <p className="no-ratings">This character isn't released yet.</p>
          )}
        </div>
      </div>
      <div className="floating-nav">
        <ScrollspyNav
          scrollTargetIds={[
            'section-profile',
            'section-skills',
            'section-skills-2',
            'section-stats',
            'section-review',
            'section-gallery'
          ]}
          activeNavClass="is-active"
          scrollDuration="500"
          headerBackground="true"
        >
          <ul className={`list-unstyled ${character.attribute}`}>
            <li>
              <a href="#section-profile">Profile</a>
            </li>
            <li>
              <a href="#section-skills">Skills</a>
            </li>
            <li>
              <a href="#section-skills-2">Transcendence</a>
            </li>
            <li>
              <a href="#section-stats">Stats</a>
            </li>
            <li>
              <a href="#section-review">Review</a>
            </li>
            <li>
              <a href="#section-gallery">Gallery</a>
            </li>
          </ul>
          <div className="mobile-arrow">
            <FontAwesomeIcon
              icon={faHandPointRight}
              className="arrow"
              width="18"
            />
          </div>
        </ScrollspyNav>
      </div>
      <div id="section-profile" className="id-section">
        <SectionHeader title="Profile" />
        <Row xs={1} xl={2} xxl={3} className="info-list">
          <Col>
            <h5>Personal information</h5>
            <div className="info-list-row">
              <div className="category">Name</div>
              <div className="details">{character.name}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Squad</div>
              <div className="details">{character.squad}</div>
            </div>
          </Col>
          <Col>
            <h5>Combat information</h5>
            <div className="info-list-row ">
              <div className="category">Rarity</div>
              <div className="details">{character.rarity}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Type</div>
              <div className="details">{character.attribute}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Class</div>
              <div className="details">{character.class}</div>
            </div>
          </Col>
          <Col>
            <h5>Release information</h5>
            <div className="info-list-row">
              <div className="category">Release date (KR)</div>
              <div className="details">
                {character.releaseDateKr ? character.releaseDateKr : '-'}
              </div>
            </div>
            <div className="info-list-row">
              <div className="category">Release date (JPN)</div>
              <div className="details">
                {character.releaseDateKr ? character.releaseDateKr : '-'}
              </div>
            </div>
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </div>
      <div id="section-skills" className="id-section">
        <SectionHeader title="Skills" />
        {character.skills && character.futureCharacter != true ? (
          <>
            <Row className="skills">
              {character.skills.map((skill, index) => {
                return (
                  <Col xs={12} xxl={6} key={index}>
                    <div className="skill-box bcm">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.attribute}`}>
                          <FontAwesomeIcon width="36" icon={faCube} />
                        </div>
                        <div className="name-section">
                          <h5 className="name">{skill.name}</h5>
                          <div className="pills">
                            <span className="skill-type pill">
                              {skill.type}
                            </span>
                            {skill.target && (
                              <span className="skill-type pill">
                                {skill.target}
                              </span>
                            )}
                            {skill.cooldown && (
                              <span className="pill cooldown">
                                <FontAwesomeIcon icon={faHistory} width="18" />
                                {skill.cooldown} turns
                              </span>
                            )}
                            {skill.atk && (
                              <span className="skill-atk pill">
                                ATK: {skill.atk}%
                              </span>
                            )}
                            {skill.matk && (
                              <span className="skill-matk pill">
                                MATK: {skill.matk}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {skill.type === 'United Attack' && (
                        <div className="special-req">
                          <div className="inner">
                            This skill will replace the Combined Attack if this
                            character is in the team:{' '}
                            <BCMCharacter
                              mode="icon"
                              slug={character.partnerSlug}
                              enablePopover
                            />
                          </div>
                        </div>
                      )}
                      {skill.hasEnhancedVersion && (
                        <div className="special-req">
                          <div className="inner-skill">
                            This skill can be enhanced with the signature Skill
                            Page.
                            <button
                              type="button"
                              onClick={() =>
                                setShowEnhancedSkill(!showEnhancedSkill)
                              }
                              className="btn btn-link-custom"
                            >
                              <FontAwesomeIcon
                                icon={faRotate}
                                className="arrow"
                                width="18"
                              />
                              {showEnhancedSkill
                                ? 'Show basic version'
                                : 'Show enhanced version'}
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="skill-content">
                        {skill.hasEnhancedVersion ? (
                          <>
                            {showEnhancedSkill ? (
                              <>
                                {skill.descriptionEnhanced && (
                                  <>
                                    {renderRichText(
                                      skill.descriptionEnhanced,
                                      options
                                    )}
                                  </>
                                )}
                                {skill.upgrades ? (
                                  <>
                                    <hr />
                                    {skill.upgrades.map((upgrade, index2) => {
                                      return (
                                        <p className="upgrades" key={index2}>
                                          <span className="upgrade-level">
                                            Lv. {index2 + 2}:
                                          </span>{' '}
                                          {upgrade.upgrade}
                                        </p>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {skill.type != 'Passive' &&
                                      skill.type != 'Unique Passive' && (
                                        <>
                                          <hr />
                                          <p>
                                            <i>
                                              Upgrade information for this skill
                                              aren't available yet.
                                            </i>
                                          </p>
                                        </>
                                      )}
                                  </>
                                )}
                                {skill.statusEnhanced && (
                                  <>
                                    <hr />
                                    <div className="buff-row">
                                      {skill.statusEnhanced.map(
                                        (status, index3) => {
                                          return (
                                            <BCMStatus
                                              status={status.name}
                                              level={status.level}
                                              value={status.value}
                                              key={index3}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {skill.description && (
                                  <>
                                    {renderRichText(skill.description, options)}
                                  </>
                                )}
                                {skill.upgrades ? (
                                  <>
                                    <hr />
                                    {skill.upgrades.map((upgrade, index2) => {
                                      return (
                                        <p className="upgrades" key={index2}>
                                          <span className="upgrade-level">
                                            Lv. {index2 + 2}:
                                          </span>{' '}
                                          {upgrade.upgrade}
                                        </p>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {skill.type != 'Passive' &&
                                      skill.type != 'Unique Passive' && (
                                        <>
                                          <hr />
                                          <p>
                                            Upgrade information for this skill
                                            aren't available yet.
                                          </p>
                                        </>
                                      )}
                                  </>
                                )}
                                {skill.status && (
                                  <>
                                    <hr />
                                    <div className="buff-row">
                                      {skill.status.map((status, index3) => {
                                        return (
                                          <BCMStatus
                                            status={status.name}
                                            level={status.level}
                                            value={status.value}
                                            key={index3}
                                          />
                                        );
                                      })}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {skill.description && (
                              <>{renderRichText(skill.description, options)}</>
                            )}
                            {skill.upgrades ? (
                              <>
                                <hr />
                                {skill.upgrades.map((upgrade, index2) => {
                                  return (
                                    <p className="upgrades" key={index2}>
                                      <span className="upgrade-level">
                                        Lv. {index2 + 2}:
                                      </span>{' '}
                                      {upgrade.upgrade}
                                    </p>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {skill.type != 'Passive' &&
                                  skill.type != 'Unique Passive' && (
                                    <>
                                      <hr />
                                      <p>
                                        Upgrade information for this skill
                                        aren't available yet.
                                      </p>
                                    </>
                                  )}
                              </>
                            )}
                            {skill.status && (
                              <>
                                <hr />
                                <div className="buff-row">
                                  {skill.status.map((status, index3) => {
                                    return (
                                      <BCMStatus
                                        status={status.name}
                                        level={status.level}
                                        value={status.value}
                                        key={index3}
                                      />
                                    );
                                  })}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>Skills are not available for this character yet.</strong>{' '}
              We will add them as soon as it is possible!
            </p>
          </Alert>
        )}
      </div>
      <div id="section-skills-2" className="id-section">
        <SectionHeader title="Transcendence" />
        <p className="smaller">
          <strong>Transcendence</strong> is a new system introduced in the
          anniversary patch in KR/JPN. It increases the level cap from 125 and
          every 5 levels you will unlock a new passive. Big thanks to{' '}
          <strong>alicanli09</strong> who gathered the information, translated
          it and shared it with us.
        </p>
        {character.transendence ? (
          <>
            <div className="transcendence box">
              <ul>
                <li>
                  <span>Level 105</span>:{' '}
                  <strong>{character.transendence.lv_105}</strong>
                </li>
                <li>
                  <span>Level 110</span>:{' '}
                  <strong>{character.transendence.lv_110}</strong>
                </li>
                <li>
                  <span>Level 115</span>:{' '}
                  <strong>{character.transendence.lv_115}</strong>
                </li>
                <li>
                  <span>Level 120</span>:{' '}
                  <strong>{character.transendence.lv_120}</strong>
                </li>
                <li>
                  <span>Level 125</span>:{' '}
                  <strong>{character.transendence.lv_125}</strong>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>
                Transcendence information are not available for this character
                yet.
              </strong>{' '}
              We will add them as soon as it is possible!
            </p>
          </Alert>
        )}
      </div>
      <div id="section-stats" className="id-section">
        <SectionHeader title="Stats" subtitle="At level 100" />
        {character.stats ? (
          <>
            <Row xs={1} xl={3} xxl={3} className="info-list stats">
              <Col>
                <div className="stat-box">
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="atk" />
                    </div>
                    <div className="details">{character.stats.atk}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="matk" />
                    </div>
                    <div className="details">{character.stats.matk}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="def" />
                    </div>
                    <div className="details">{character.stats.def}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="hp" />
                    </div>
                    <div className="details">{character.stats.hp}</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="stat-box">
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="acc" />
                    </div>
                    <div className="details">{character.stats.acc}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="dmgres" />
                    </div>
                    <div className="details">{character.stats.dmgres}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="crit" />
                    </div>
                    <div className="details">{character.stats.crit}%</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="critdmg" />
                    </div>
                    <div className="details">{character.stats.critdmg}%</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="stat-box">
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="critres" />
                    </div>
                    <div className="details">{character.stats.critres}%</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="speed" />
                    </div>
                    <div className="details">{character.stats.speed}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="pen" />
                    </div>
                    <div className="details">{character.stats.pen}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <BCMStat stat="end" />
                    </div>
                    <div className="details">{character.stats.end}</div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>Stats are not available for this character yet.</strong>{' '}
              We will add them as soon as it is possible!
            </p>
          </Alert>
        )}
        <div className="fuse-ad-placeholder" data-fuse="22844297001"></div>
      </div>
      <div id="section-review" className="id-section">
        <SectionHeader title="Review" />
        {!character.isReviewPending ? (
          <div className="section-analysis">
            <div className="analysis">
              {character.review ? (
                <div>
                  <div className={`review raw`}>
                    {character.review ? (
                      <>{renderRichText(character.review, options)}</>
                    ) : (
                      <p>Review is pending.</p>
                    )}
                  </div>
                  <h5 className="analysis-header">Ratings</h5>
                  {!character.futureCharacter &&
                  character.ratings.kr_pve != '1' ? (
                    <div className="character-ratings">
                      <RatingBox
                        game="nikke"
                        rating={character.ratings.global_pve}
                        ratingName="Global PVE"
                      />
                      <RatingBox
                        game="nikke"
                        rating={character.ratings.global_pvp}
                        ratingName="Global PVP"
                      />
                      <RatingBox
                        game="nikke"
                        rating={character.ratings.kr_pve}
                        ratingName="KR/JPN PVE"
                      />
                      <RatingBox
                        game="nikke"
                        rating={character.ratings.kr_pvp}
                        ratingName="KR/JPN PVP"
                      />
                    </div>
                  ) : (
                    <p className="no-ratings">Ratings are pending review.</p>
                  )}
                  {character.slug === 'asta-black' && (
                    <>
                      <SectionHeader title="Video guide" />
                      <Row xs={1} xl={2} className="video-row">
                        <Col>
                          <YoutubeEmbed embedId="-LkK7yOywms" />
                        </Col>
                      </Row>
                    </>
                  )}
                  {character.slug === 'mereoleona-vermillion' && (
                    <>
                      <SectionHeader title="Video guide" />
                      <Row xs={1} xl={2} className="video-row">
                        <Col>
                          <YoutubeEmbed embedId="vl-nvqh5NIE" />
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              ) : (
                <Alert variant="primary">
                  <Alert.Heading>
                    No review is available for this character yet
                  </Alert.Heading>
                  <p>We will add it as soon as it is possible!</p>
                </Alert>
              )}
            </div>
          </div>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>Review is not available for this character yet.</strong>{' '}
              We will add it as soon as it is possible!
            </p>
          </Alert>
        )}
      </div>
      <div id="section-gallery" className="id-section">
        <SectionHeader title="Gallery" />
        <h5>Full design</h5>
        <Alert variant="primary">
          <Alert.Heading>
            No full image is not available for this character yet
          </Alert.Heading>
          <p>We will add it as soon as it is possible!</p>
        </Alert>
      </div>
    </DashboardLayout>
  );
};

export default BCMCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Black Clover M | Prydwen Institute'}
      description={
        character.name +
        ' is one of the ' +
        character.attribute +
        ' characters available in Black Clover Mobile who belongs to the ' +
        character.squad +
        ' squad.'
      }
      image={character.imageSmall}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulBlackCloverCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...BlackCloverCharacterFieldsFragment
      }
    }
  }
`;
